import React, { FC } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import loadable from '@wix/yoshi-flow-editor/loadable';

import { ControllerProps } from '../../../types';

const Widget = loadable(() => {
  return import(/* webpackChunkName: "RootWidget" */ './Widget');
});

const Root: FC<
  React.PropsWithChildren<WidgetProps<ControllerProps | undefined>>
> = (props) => {
  if (!props.isRendered) {
    return null;
  }

  return <Widget {...props} />;
};

export default Root;
